import axios from "axios";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const $toast = useToast();

export const registration_app = {
    data() {        
        return {
            user: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                role: 'user'
            },
            errors: {},
            validate: false
        };
    },
    mounted() {
        this.get_user();
    },
    methods: {
        get_user() {
            let params = window.location.search
            axios.get("/users/sign_up.json"+params)
                .then(res => {
                    this.user = res.data
                    console.log('traje esto ', res.data);
                })
        },
        validateForm() {
            this.errors = {};
            let valid = true;

            if (!this.user.first_name) {
                this.errors.first_name = 'Nombres es obligatorio.';
                valid = false;
            }

            if (this.user.first_name.length < 3){
                this.errors.first_name = 'el nombre debe tener mínimo 3 caracteres.';
                valid = false;
            }

            if (!this.user.last_name) {
                this.errors.last_name = 'Apellidos es obligatorio.';
                valid = false;
            }

            if (this.user.last_name.length < 3){
                this.errors.last_name = 'El apellido debe tener mínimo 3 caracteres.';
                valid = false;
            }

            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!this.user.email) {
                this.errors.email = 'Correo Electrónico es obligatorio.';
                valid = false;
            } else if (!emailPattern.test(this.user.email)) {
                this.errors.email = 'Formato de correo electrónico inválido.';
                valid = false;
            }

            const phonePattern = /^([0-9]{10}$)/

            if (!this.user.phone) {
                this.errors.phone = 'El número de celular es obligatorio.';
                valid = false;
            } else if (!phonePattern.test(this.user.phone)) {
                this.errors.phone = 'Número de celular inválido.';
                valid = false;
            }

            const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!this.user.password) {
                this.errors.password = 'Contraseña es obligatorio.';
                valid = false;
            } else if (!passwordPattern.test(this.user.password)) {
                this.errors.password = 'La contraseña debe tener una longitud mínima de 8 caracteres, debe contener al menos una letra mayúscula, una letra minúscula, un dígito, y un carácter especial de los siguientes (@$!%*?&)';
                valid = false;
            }

            if (this.user.password !== this.user.password_confirmation) {
                this.errors.password_confirmation = 'Las contraseñas no coinciden.';
                valid = false;
            }

            if (valid) {
                this.enviar();
            } else {
                this.validate = false;
            }
        },
        enviar() {
            axios.post("/users.json", { user: this.user, authenticity_token: window.authenticity_token })
                .then(res => {
                    window.location.replace("/users/sign_in?status=successplease_confirm");
                })
                .catch(errors => {
                    for (let error in errors.response.data) {
                        for (let e of errors.response.data[error]) {
                            $toast.error(error + ": " + e, { position: "top-right", duration: 5000 });
                        }
                    }
                });
        }
    }
};
